import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'partners',
        title: 'Partners',
        translate: 'NAV.PARTNERS.TITLE',
        type: 'group',
        children: [
            {
                id: 'list',
                title: 'All Partners',
                url: '/partners/list',
                translate: 'NAV.PARTNERS.LIST.TITLE',
                icon: 'bookmarks',
                type: 'item'
            },/*
            {
                id: 'profileUpdateApprovals',
                title: 'Profile Update Approvals',
                url: '/partners/profile-update-approvals',
                translate: 'NAV.PARTNERS.PROFILE_UPDATE_APPROVALS.TITLE',
                type: 'item'
            },*/
            {
                id: 'services',
                title: 'Services',
                url: '/partners/services',
                translate: 'NAV.PARTNERS.SERVICES.TITLE',
                icon: 'style',
                type: 'item'
            },
        ]
    },
    {
        id: 'events',
        title: 'Events',
        translate: 'NAV.EVENTS.TITLE',
        type: 'group',
        children: [
            {
                id: 'list',
                title: 'All Events',
                translate: 'NAV.EVENTS.LIST.TITLE',
                type: 'item',
                icon: 'event',
                url: '/events/list'
            },
            {
                id: 'categories',
                title: 'Event Categories',
                translate: 'NAV.EVENTS.CATEGORY.TITLE',
                type: 'item',
                icon: 'event',
                url: '/events/categories'
            }/*,
            {
                id: 'subscribers',
                title: 'Event Subscribers',
                translate: 'NAV.EVENTS.SUBSCRIBERS.TITLE',
                type: 'item',
                icon: 'email',
                url: '/events/subscribers'
            }*/
        ]
    },
    {
        id: 'magazine',
        title: 'Magazine',
        translate: 'NAV.MAGAZINE.TITLE',
        type: 'group',
        children: [
            {
                id: 'list',
                title: 'Articles',
                translate: 'NAV.MAGAZINE.LIST.TITLE',
                type: 'item',
                icon: 'description',
                url: '/magazine/list'
            },
            {
                id: 'tags',
                title: 'Article Tags',
                translate: 'NAV.MAGAZINE.TAGS.TITLE',
                type: 'item',
                icon: 'link',
                url: '/magazine/tags'
            }
        ]
    },
    {
        id: 'admin',
        title: 'Administration',
        translate: 'NAV.ADMINISTRATION.TITLE',
        type: 'group',
        children: [
            {
                id: 'users',
                title: 'Manage Users',
                translate: 'NAV.ADMINISTRATION.MANAGE_USERS.TITLE',
                type: 'item',
                icon: 'people',
                url: '/admin/users'
            }
        ]
    },
    {
        id: 'website-config',
        title: 'Website Config',
        translate: 'NAV.WEBSITE_CONFIG.TITLE',
        type: 'group',
        children: [
            {
                id: 'users',
                title: 'Background Images',
                translate: 'NAV.WEBSITE_CONFIG.BACKGROUND_IMAGE.TITLE',
                type: 'item',
                icon: 'photo',
                url: '/website-config/background-image'
            }
        ]
    },
    {
        id: 'service-enquiry',
        title: 'Service Enquiry',
        translate: 'NAV.SERVICE_ENQUIRY.TITLE',
        type: 'group',
        children: [
            {
                id: 'users',
                title: 'Service Forms',
                translate: 'NAV.SERVICE_ENQUIRY.SERVICE_FORM.TITLE',
                type: 'item',
                icon: 'reorder',
                url: '/service-enquiry/service-form'
            },
            {
                id: 'submission',
                title: 'Form Submissions',
                translate: 'NAV.SERVICE_ENQUIRY.FORM_SUBMISSION.TITLE',
                type: 'item',
                icon: 'description',
                url: '/service-enquiry/form-submission'
            }
        ]
    }
];
