export const locale = {
    lang: 'en',
    data: {
        PARTNERS: {
            LIST: {
                TITLE: 'Partners',
                CREATE_BUTTON: 'Create partner',
                SEARCH_TEXT: 'Search for a partner',
                TABLE: {
                    TITLE: 'Title',
                    EMAIL: 'Email',
                    TELEPHONE_NUMBER: 'Telephone Number',
                    ACTIVE: 'Active',
                },
            },
            CREATE: {
                TITLE: 'Create a new partner',
                SUBMIT_BUTTON: 'Create partner',
            },
            UPDATE: {
                TITLE: 'Update partner',
                SUBMIT_BUTTON: 'Create partner',
            },
        },
        PARTNERS_SERVICES: {
            LIST: {
                TITLE: 'Partner Services',
                CREATE_BUTTON: 'Create service',
                SEARCH_TEXT: 'Search for a service',
                TABLE: {
                    SLUG: 'Url slug',
                    TITLE: 'Service name',
                    FEATURED: 'Featured',
                    ACTIVE: 'Active',
                    ORDER: 'Order',
                },
            },
            CREATE: {
                TITLE: 'Create a new service',
                SUBMIT_BUTTON: 'Create service',
            },
            UPDATE: {
                TITLE: 'Update service',
                SUBMIT_BUTTON: 'Update a service',
            },
        },
        PARTNERS_PARTNER_ENQUIRY: {
            LIST: {
                TITLE: 'Partner Enquiries',
                CREATE_BUTTON: 'Create a new enquiry',
                SEARCH_TEXT: 'Search for an enquiry',
                TABLE: {
                    NAME: 'Name',
                    EMAIL: 'Email',
                    STATUS: 'Status',
                },
            },
            UPDATE: {
                TITLE: 'View enquiry',
                SUBMIT_BUTTON: 'Close',
            },
        },
        PARTNERS_PARTNER_VERSION: {
            LIST: {
                TABLE: {
                    DATE_CREATED: 'Date Created',
                    STATUS: 'Status',
                },
            },
            CREATE: {
                TITLE: 'Create a new service',
                SUBMIT_BUTTON: 'Create service',
            },
            UPDATE: {
                TITLE: 'Update service',
                SUBMIT_BUTTON: 'Update a service',
            },
        },
        PARTNERS_PARTNER_USER: {
            LIST: {
                CREATE_BUTTON: 'Create user',
                TABLE: {
                    EMAIL: 'Email',
                },
                BUTTONS: {
                    DELETE: {
                        BUTTON_TITLE: 'Delete user from partner',
                        MODAL_TITLE: 'Delete user from partner',
                    },
                },
            },
            CREATE: {
                TITLE: 'Add user to partner',
                SUBMIT_BUTTON: 'Add user',
            },
            UPDATE: {
                TITLE: 'User',
                SUBMIT_BUTTON: 'Reset',
            },
        },
        PARTNERS_PARTNER_BILLING: {
            LIST: {
                CREATE_BUTTON: 'Create a new billing subscription',
                TABLE: {
                    PLAN: 'Plan',
                    EXPIRY_DATE: 'Expiry Date',
                },
            },
            CREATE: {
                TITLE: 'Create a new billing subscription',
                SUBMIT_BUTTON: 'Create',
            },
            UPDATE: {
                TITLE: 'Update a new billing subscription',
                SUBMIT_BUTTON: 'Update',
            },
        },
        MAGAZINE_TAG: {
            LIST: {
                TITLE: 'Article Tags',
                CREATE_BUTTON: 'Create a tag',
                SEARCH_TEXT: 'Search for a tag',
                TABLE: {
                    TITLE: 'Tag name',
                    ACTIVE: 'Active',
                },
            },
            CREATE: {
                TITLE: 'Create a tag',
                SUBMIT_BUTTON: 'Create tag',
            },
            UPDATE: {
                TITLE: 'Update tag',
                SUBMIT_BUTTON: 'Create tag',
            },
        },
        MAGAZINE_ARTICLE: {
            LIST: {
                TITLE: 'Articles',
                CREATE_BUTTON: 'Create an article',
                SEARCH_TEXT: 'Search for an article',
                TABLE: {
                    TITLE: 'Article title',
                    FEATURED: 'Featured',
                    ACTIVE: 'Active',
                },
            },
            CREATE: {
                TITLE: 'Create an article',
                SUBMIT_BUTTON: 'Create article',
            },
            UPDATE: {
                TITLE: 'Update article',
                SUBMIT_BUTTON: 'Create article',
            },
        },
        EVENTS_EVENT: {
            LIST: {
                TITLE: 'Events',
                CREATE_BUTTON: 'Create an event',
                SEARCH_TEXT: 'Search for an event',
                TABLE: {
                    TITLE: 'Event title',
                    ACTIVE: 'Active',
                },
            },
            CREATE: {
                TITLE: 'Create an event',
                SUBMIT_BUTTON: 'Create event',
            },
            UPDATE: {
                TITLE: 'Update event',
                SUBMIT_BUTTON: 'Create event',
            },
        },
        EVENTS_CATEGORY: {
            LIST: {
                TITLE: 'Event Categories',
                CREATE_BUTTON: 'Create a category',
                SEARCH_TEXT: 'Search for a category',
                TABLE: {
                    TITLE: 'Category title',
                    ACTIVE: 'Active',
                },
            },
            CREATE: {
                TITLE: 'Create an event category',
                SUBMIT_BUTTON: 'Create category',
            },
            UPDATE: {
                TITLE: 'Update event category',
                SUBMIT_BUTTON: 'Create event category',
            },
        },
        ADMIN_USERS: {
            LIST: {
                TITLE: 'Admin Users',
                SEARCH_TEXT: 'Search admin users',
                CREATE_BUTTON: 'Create a user',
                TABLE: {
                    NAME: 'Name',
                    ACTIVE: 'Active'
                }
            }
        },
        ADMIN_USERS_USER: {
            CREATE: {
                TITLE: 'Create a new admin user'
            },
            UPDATE: {
                TITLE: 'Update a new admin user'
            }
        },
        WEBSITE_CONFIG_BACKGROUND_IMAGE: {
            LIST: {
                TITLE: 'Background Images',
                CREATE_BUTTON: 'Create a background image',
                SEARCH_TEXT: 'Search for a background image',
                TABLE: {
                    LABEL: 'Image Label',
                    ACTIVE: 'Active',
                },
            },
            CREATE: {
                TITLE: 'Create a background image',
                SUBMIT_BUTTON: 'Create image',
            },
            UPDATE: {
                TITLE: 'Update background image',
                SUBMIT_BUTTON: 'Create background image',
            },
        },
        SERVICE_ENQUIRY_SERVICE_FORM: {
            LIST: {
                TITLE: 'Service Forms',
                CREATE_BUTTON: 'Create a new service form',
                SEARCH_TEXT: 'Search for a service form',
                TABLE: {
                    TITLE: 'Service Form Name',
                    ACTIVE: 'Active',
                },
            },
            CREATE: {
                TITLE: 'Create a service form',
                SUBMIT_BUTTON: 'Create form',
            },
            UPDATE: {
                TITLE: 'Update service form',
                SUBMIT_BUTTON: 'Update form',
            },
        },
        SERVICE_ENQUIRY_SERVICE_FORM_VERSION: {
            LIST: {
                TITLE: 'Service Form Versions',
                CREATE_BUTTON: 'Create a new service form version',
                SEARCH_TEXT: 'Search for a version',
                TABLE: {
                    TITLE: 'Version Name',
                    ACTIVE: 'Active',
                },
            },
            CREATE: {
                TITLE: 'Create a service form version',
                SUBMIT_BUTTON: 'Create version',
            },
            UPDATE: {
                TITLE: 'Update service form version',
                SUBMIT_BUTTON: 'Update form version',
            },
        },
        SERVICE_ENQUIRY_FORM_SUBMISSION: {
            LIST: {
                TITLE: 'Service Form Submissions',
                SEARCH_TEXT: 'Search for a submission',
                TABLE: {
                    SERVICE_TITLE: 'Service',
                    EMAIL: 'Email',
                    DATE_CREATED: 'Date submitted',
                    STATUS: 'Status'
                },
            },
            UPDATE: {
                TITLE: 'Update submission',
                SUBMIT_BUTTON: 'Update submission',
            },
        },
    }
};
