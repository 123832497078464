import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListiqueAuthGuard, ListiqueAuthPermissions } from '@listique/auth';

const routes: Routes = [
    {
        path: 'auth',
        children: [
            { path: 'login', loadChildren: () => import(`./main/auth/login/login.module`).then(m => m.LoginModule) }
        ]
    },
    {
        path: '',
        canActivate: [ListiqueAuthGuard],
        data: { permissions: [ListiqueAuthPermissions.ADMIN, ListiqueAuthPermissions.SUPER_ADMIN] },
        children: [
            // { path: '', loadChildren: () => import(`./main/home/home.module`).then(m => m.HomeModule) },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/partners/list'
            },
            { path: '404', loadChildren: () => import(`./main/404/error-404.module`).then(m => m.Error404Module) },
            {
                path: 'partners',
                loadChildren: () => import('./main/routing/partner-routing.module').then(m => m.PartnerRoutingModule),
            },
            {
                path: 'magazine',
                loadChildren: () => import('./main/routing/magazine-routing.module').then(m => m.MagazineRoutingModule),
            },
            {
                path: 'events',
                loadChildren: () => import('./main/routing/events-routing.module').then(m => m.EventsRoutingModule),
            },
            {
                path: 'admin',
                loadChildren: () => import('./main/routing/admin-routing.module').then(m => m.AdminRoutingModule),
            },
            {
                path: 'website-config',
                loadChildren: () => import('./main/routing/website-config-routing.module').then(m => m.WebsiteConfigRoutingModule),
            },
            {
                path: 'service-enquiry',
                loadChildren: () => import('./main/routing/service-enquiry-routing.module').then(m => m.ServiceEnquiryRoutingModule),
            },
            {
                path: '**',
                redirectTo: '/404'
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule { }
