export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'MANAGE': {
                'TITLE': 'Manage',
                'EVENTS': {
                    'TITLE': 'Events'
                },
                'MAGAZINE': {
                    'TITLE': 'Magazine'
                },
            },
            'PARTNERS': {
                'TITLE': 'Partners',
                'LIST': {
                    'TITLE': 'Partners'
                },
                'PROFILE_UPDATE_APPROVALS': {
                    'TITLE': 'Profile Update Approvals'
                },
                'SERVICES': {
                    'TITLE': 'Partner Services'
                }
            },
            'EVENTS': {
                'TITLE': 'Events',
                'LIST': {
                    'TITLE': 'All Events'
                },
                'CATEGORY': {
                    'TITLE': 'Event Categories'
                },
                'SUBSCRIBERS': {
                    'TITLE': 'Event Subscribers'
                },
            },
            'MAGAZINE': {
                'TITLE': 'Magazine',
                'LIST': {
                    'TITLE': 'Articles'
                },
                'TAGS': {
                    'TITLE': 'Tags'
                },
            },
            'ADMINISTRATION': {
                'TITLE': 'Administration',
                'MANAGE_USERS': {
                    'TITLE': 'Manage Admin Users'
                }
            },
            'WEBSITE_CONFIG': {
                'TITLE': 'Website Config',
                'BACKGROUND_IMAGE': {
                    'TITLE': 'Background Images'
                }
            },
            'SERVICE_ENQUIRY': {
                'TITLE': 'Service Enquiry',
                'SERVICE_FORM': {
                    'TITLE': 'Service Forms'
                },
                'FORM_SUBMISSION': {
                    'TITLE': 'Form Submissions'
                }
            }
        }
    }
};
